import Grid from '@mui/system/Unstable_Grid/Grid';
import { Link, List, ListItem, Typography } from '@mui/material';

import '../../../App.css';

export const RecipeSidebar = () => {
  return (
    <>
      <Typography sx={{ marginY: 3 }} variant="h3">
        Sidebar Title
      </Typography>
      <List>
        <ListItem disableGutters>
          <Link>Link 1</Link>
        </ListItem>
        <ListItem disableGutters>
          <Link>Link 2</Link>
        </ListItem>
      </List>
      <Typography>
        Aenean in placerat ipsum. Sed nec magna rhoncus, efficitur ante in,
        fringilla metus. Aenean augue sem, tempor egestas auctor sit amet,
        dapibus et leo. Cras non porta odio. Curabitur sapien est, luctus in
        urna eget, interdum vestibulum tortor. Integer egestas nec arcu quis
        consequat. Nullam vitae nibh et ante feugiat tincidunt. Aliquam
        pellentesque ipsum lacus, sed ullamcorper nulla vestibulum vel.
        Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
        cubilia curae; Phasellus pharetra viverra nisl, id rutrum libero
        molestie vitae. Ut bibendum tempor mauris, convallis tincidunt ipsum
        iaculis eget. Phasellus tincidunt nec eros in vestibulum. Etiam quis
        diam vitae mi convallis rhoncus eu ac sem. Suspendisse id eros finibus,
        efficitur arcu sed, aliquet mauris. Aliquam est arcu, laoreet id dapibus
        a, hendrerit non elit. Etiam condimentum interdum ipsum a rhoncus.
        Vivamus at venenatis ex.
      </Typography>
    </>
  );
};
