import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@mui/system/Unstable_Grid/Grid';
import { Container, Typography } from '@mui/material';

import { RecipeBreadcrumbs } from './Breadcrumbs';
import { RecipeSidebar } from './Sidebar';
import { RecipeCard } from './Card';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';

import { Spinner } from '../../Shared/Spinner';
import { Error } from '../../Shared/Error';
import '../../../App.css';

export const RecipeView = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recipe, setRecipe] = useState({} as RecipeProps);

  const { recipeId } = useParams();
  const theme = useTheme();

  // TODO Provide routing for production
  const endpoint = `http://localhost:8000/recipes/${recipeId}/`;

  const fetchRecipe = async () => {
    setLoading(true);
    try {
      const resp = await fetch(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!resp.ok) {
        console.log(`could not fetch recipe ${recipeId}`);
        setError(true);
      } else {
        const data = await resp.json();
        setRecipe(data);
      }
    } catch (error) {
      console.error(error);
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRecipe();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <RecipeBreadcrumbs
        pageName={recipe.title}
        pageUrl={`/test/sample`}
        // TODO Swap in real url
        // pageUrl={recipe.url}
      />

      <Grid container spacing={3}>
        <Grid md={8} xs={12}>
          <Grid xs={12}>
            <Typography color="primary" sx={{ marginY: 3 }} variant="h1">
              {recipe.title}
            </Typography>
          </Grid>

          <Grid xs={12}>
            <Typography color="primary">{recipe.description}</Typography>
          </Grid>

          <Grid xs={12}>
            <Box
              sx={{
                backgroundColor: theme.palette.grey[200],
                height: '200px',
                marginY: 6,
                width: '100%',
              }}
            >
              image
            </Box>
          </Grid>

          <Grid xs={12}>
            <Typography color="primary" sx={{ marginY: 3 }} variant="h2">
              Section Title
            </Typography>
            <Typography color="primary">{recipe.intro}</Typography>
          </Grid>

          <Grid sx={{ marginY: '3rem' }} xs={12}>
            <RecipeCard
              author={recipe.author}
              description={recipe.description}
              intro={recipe.intro}
              conclusion={recipe.conclusion}
              recipe_steps={recipe.recipe_steps}
              recipe_ingredients={recipe.recipe_ingredients}
              title={recipe.title}
            />
          </Grid>

          <Grid xs={12}>
            <Typography color="primary" sx={{ marginY: 3 }} variant="h2">
              Happy Baking
            </Typography>
            <Typography color="primary">{recipe.conclusion}</Typography>
          </Grid>
        </Grid>

        <Grid md={4} xs={12}>
          <RecipeSidebar />
        </Grid>
      </Grid>
    </Container>
  );
};
