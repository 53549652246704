import React, { useState } from 'react';

import Grid from '@mui/system/Unstable_Grid/Grid';
import {
  Checkbox,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import '../../../App.css';
import { useTheme } from '@mui/material/styles';

export const RecipeCard = (recipe: RecipeProps) => {
  const [checked, setChecked] = useState([]);

  const theme = useTheme();

  // @ts-ignore
  const handleToggle = (value) => () => {
    // @ts-ignore
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      // @ts-ignore
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <Grid sx={{ border: `2px solid ${theme.palette.secondary.main}` }} xs={12}>
      <Grid xs={12}>
        <Typography sx={{ marginTop: 1 }} variant="h4">
          {recipe.title}
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Typography>{recipe.description}</Typography>
      </Grid>
      <Grid xs={12}>
        <Typography sx={{ marginTop: 3 }} variant="h5">
          Ingredients
        </Typography>
        <List disablePadding>
          {recipe.recipe_ingredients &&
            recipe.recipe_ingredients.map((ingredient) => {
              return (
                <ListItemButton
                  dense
                  disableGutters
                  disableTouchRipple
                  key={ingredient.id}
                  onClick={handleToggle(ingredient.id)}
                >
                  <ListItemIcon>
                    <Checkbox
                      // @ts-ignore
                      checked={checked.indexOf(ingredient.id) !== -1}
                      color="primary"
                      tabIndex={-1}
                      disableRipple
                      // @ts-ignore
                      inputProps={{ 'aria-labelledby': ingredient.id }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    // @ts-ignore
                    id={ingredient.id}
                    primary={ingredient.name}
                  />
                </ListItemButton>
              );
            })}
        </List>
      </Grid>
      <Grid xs={12}>
        <Typography sx={{ marginY: 3 }} variant="h5">
          Directions
        </Typography>

        {recipe.recipe_steps &&
          recipe.recipe_steps.map((direction, index: number) => {
            return (
              <span key={direction.id}>
                <Typography sx={{ marginTop: 3 }} variant="h6">
                  <b>Step {direction.step_number}</b>
                </Typography>
                <Typography>{direction.instruction}</Typography>
              </span>
            );
          })}
      </Grid>
    </Grid>
  );
};
