import React, { useState } from 'react';

import {
  AppBar,
  Button,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import { colorConfig } from '../Config/ColorConfig';
import { Logo } from '../../Shared/Logo';

const links = ['Home', 'Recipes', 'Sourdough'];

export const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const theme = useTheme();

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // TODO Fix me for React event type
  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: colorConfig.color2.two,
        borderBottom: `1px solid ${theme.palette.secondary.light}`,
        boxShadow: 'none',
      }}
    >
      <Container>
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Logo />
          </Box>
          <Box
            sx={{
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
            }}
          >
            <IconButton
              aria-label="site menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(e) => handleOpenNavMenu(e)}
              // size="large"
              sx={{
                color: theme.palette.primary.contrastText,
              }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              keepMounted
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {links.map((link) => (
                <MenuItem key={link} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{link}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
            }}
          >
            <Logo isSmall />
          </Box>
          <Box
            sx={{
              alignItems: 'baseline',
              justifyContent: 'flex-end',
              display: { xs: 'none', md: 'flex' },
              flexGrow: 1,
            }}
          >
            {links.map((link) => (
              <Button
                disableFocusRipple
                disableTouchRipple
                key={link}
                onClick={handleCloseNavMenu}
                sx={{
                  color: theme.palette.primary.contrastText,
                  display: 'block',
                  fontWeight: 700,
                  my: 2,
                  marginX: 2,
                }}
              >
                {link}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
