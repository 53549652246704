import React, { useState } from 'react';

import Grid from '@mui/system/Unstable_Grid/Grid';
import { Container, Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';

export const Error = () => {
  const theme = useTheme();
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid md={8} xs={12} sx={{ minHeight: '500px' }}>
          <Grid md={8} xs={12}>
            <Grid xs={12}>
              <Typography color="primary" sx={{ marginY: 3 }} variant="h1">
                Error
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography color="primary">
                There was an error fetching content. Please refresh the page to
                try again.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
