import React, { useState } from 'react';

import {
  AppBar,
  Button,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { colorConfig } from '../../../Layout/Config/ColorConfig';
import { Logo } from '../../../Shared/Logo';

const links = ['Home', 'Recipes', 'Sourdough'];
const pageName = 'mr yeast';
const fontFamily = 'Fugaz One';

export const Promo = () => {
  const theme = useTheme();

  return (
    <Grid
      xs={12}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '25%',
      }}
    >
      <Logo isDark useStroke />
    </Grid>
  );
};
