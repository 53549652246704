import React from 'react';

// Supports weights 200-900
import '@fontsource-variable/nunito-sans';
import '@fontsource/fugaz-one';

import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { AlertBanner } from './Header/AlertBanner';
import { Outlet } from 'react-router-dom';

export const Layout = () => {
  return (
    <>
      <Header />
      {/* <AlertBanner /> */}
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};
