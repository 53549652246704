import Grid from '@mui/system/Unstable_Grid/Grid';
import { Breadcrumbs, Link, Typography } from '@mui/material';

import '../../../App.css';

interface RecipeBreadcrumbsProps {
  pageName: string;
  pageUrl: string;
}

export const RecipeBreadcrumbs = (props: RecipeBreadcrumbsProps) => {
  return (
    <Grid container>
      <Grid xs={12} sx={{ marginY: 2 }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator=">"
          sx={{ fontSize: '1rem' }}
        >
          <Link href="/" underline="none">
            Home
          </Link>
          <Link
            href="/material-ui/getting-started/installation/"
            underline="none"
          >
            Recipes
          </Link>
          <Link href={props.pageUrl} underline="none">
            {props.pageName}
          </Link>
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};
