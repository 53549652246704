import React from 'react';

import Grid from '@mui/system/Unstable_Grid/Grid';
import { Link, List, ListItem } from '@mui/material';
import { Typography } from '@mui/material';

import CopyrightIcon from '@mui/icons-material/Copyright';
import { Logo } from '../../Shared/Logo';

const currentYear = new Date().getFullYear();

export const Footer = () => {
  return (
    <Grid container spacing={2} sx={{ marginBottom: 3, marginTop: 9 }}>
      <Grid xs={4}>
        <List>
          <ListItem sx={{ justifyContent: 'center' }}>
            <Link color="primary" href="#" underline="none">
              Section 1
            </Link>
          </ListItem>
          <ListItem sx={{ justifyContent: 'center' }}>
            <Link color="primary" href="#" underline="none">
              Section 2
            </Link>
          </ListItem>
        </List>
      </Grid>

      <Grid xs={4}>
        <List>
          <ListItem sx={{ justifyContent: 'center' }}>
            <Link color="primary" href="#" underline="none">
              Section 3
            </Link>
          </ListItem>
          <ListItem sx={{ justifyContent: 'center' }}>
            <Link color="primary" href="#" underline="none">
              Section 4
            </Link>
          </ListItem>
        </List>
      </Grid>

      <Grid xs={4}>
        <List>
          <ListItem sx={{ justifyContent: 'center' }}>
            <Link color="primary" href="#" underline="none">
              Section 5
            </Link>
          </ListItem>
          <ListItem sx={{ justifyContent: 'center' }}>
            <Link color="primary" href="#" underline="none">
              Section 6
            </Link>
          </ListItem>
        </List>
      </Grid>

      <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Logo isDark isSmall />
      </Grid>

      <Grid
        xs={12}
        color="gray"
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <Typography>Made in Madison, Wisconsin</Typography>
      </Grid>

      <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography
          color="gray"
          sx={{
            alignItems: 'center',
            display: 'flex',
            fontSize: '1rem',
            justifyContent: 'center',
          }}
        >
          <CopyrightIcon sx={{ fontSize: '1rem' }} /> {currentYear} mryeast.com
        </Typography>
      </Grid>
    </Grid>
  );
};
