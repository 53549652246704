import React from 'react';

import Grid from '@mui/system/Unstable_Grid/Grid';
import { Container, Typography } from '@mui/material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AcUnitIcon from '@mui/icons-material/AcUnit';

import '../../../App.css';

const ingredients = [
  {
    id: 1,
    name: 'ingredient 1',
  },
  {
    id: 2,
    name: `long ingredient name is very long longer than a normal ingredient 
    name it may even span multiple lines  or possibly three whole complete lines 
    maybe if you write it long enough ten fourty fourty two five 2`,
  },
  {
    id: 3,
    name: 'ingredient 3',
  },
];
const directions = [
  {
    id: 1,
    name: 'Get the ingredients',
    description: 'Gather all the things for the thing so we can make the thing',
  },
  {
    id: 2,
    name: 'Put together all ze ingredients',
    description: 'For this part, get a huge pot to put the ingredients into',
  },
  {
    id: 3,
    name: 'Cook everything together all at once forever',
    description: 'Cook it, cook it real good.',
  },
];
const recipe = {
  ingredients: ingredients,
  directions: directions,
  name: 'This is the recipe name',
  url: '/recipes/id',
  description: `This is the recipe description. Aenean in placerat ipsum. Sed nec magna rhoncus, efficitur ante
              in, fringilla metus. Aenean augue sem, tempor egestas auctor sit
              amet, dapibus et leo. Cras non porta odio. Curabitur sapien est,
              luctus in urna eget, interdum vestibulum tortor. Integer egestas
              nec arcu quis consequat. Nullam vitae nibh et ante feugiat
              tincidunt. Aliquam pellentesque ipsum lacus, sed ullamcorper nulla
              vestibulum vel. Vestibulum ante ipsum primis in faucibus orci
              luctus et ultrices posuere cubilia curae; Phasellus pharetra
              viverra nisl, id rutrum libero molestie vitae. Ut bibendum tempor
              mauris, convallis tincidunt ipsum iaculis eget. Phasellus
              tincidunt nec eros in vestibulum. Etiam quis diam vitae mi
              convallis rhoncus eu ac sem. Suspendisse id eros finibus,
              efficitur arcu sed, aliquet mauris. Aliquam est arcu, laoreet id
              dapibus a, hendrerit non elit. Etiam condimentum interdum ipsum a
              rhoncus. Vivamus at venenatis ex.`,
};

const list = ['a', 'b', 'c', 'd', 'e', 'f'];

const categories = [
  'Bread',
  'Cakes',
  'Cookies',
  'Shortbreads',
  'Sourdough',
  'Enriched',
];

export const HomeView = () => {
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: theme.palette.secondary.light,
          borderBottom: `1px solid ${theme.palette.secondary.main}`,
          color: theme.palette.primary.contrastText,
          marginBottom: 6,
          marginTop: 0,
          // paddingLeft: '10%',
        }}
        // maxWidth="xl"
      >
        {categories.map((category) => (
          <Grid xs={6} sm={4} md={2}>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              <Button color="secondary" disableFocusRipple disableTouchRipple>
                <AcUnitIcon /> {category}
              </Button>
            </Typography>
          </Grid>
        ))}
      </Grid>

      <Container>
        <Grid container spacing={3}>
          {list.map(() => (
            <Grid xs={6} sm={6} md={4}>
              <Card>
                <CardMedia
                  sx={{ height: 140 }}
                  image="/static/images/IMG_2719.jpeg"
                  title="bread head"
                />
                {/* <CardHeader
                sx={{
                  backgroundColor: theme.palette.primary.light,
                  color: theme.palette.primary.contrastText,
                }}
              > */}
                {/* <Grid xs={12}>header</Grid> */}
                {/* </CardHeader> */}
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    color="primary"
                  >
                    Bread
                  </Typography>
                  <Typography variant="body2" color="primary">
                    Bread takes many forms around the world. This recipe is
                    second to none.
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'end' }}>
                  <Button color="secondary" size="small">
                    See recipe
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>

    //       <Grid xs={12}>
    //         <Typography>{recipe.description}</Typography>
    //       </Grid>

    //       <Grid xs={12}>
    //         <Box
    //           sx={{
    //             backgroundColor: theme.palette.grey[200],
    //             height: '200px',
    //             marginY: 6,
    //             width: '100%',
    //           }}
    //         >
    //           image
    //         </Box>
    //       </Grid>

    //       <Grid xs={12}>
    //         <Typography sx={{ marginY: 3 }} variant="h2">
    //           Section Title
    //         </Typography>
    //         <Typography>
    //           Vestibulum massa diam, lacinia a urna ac, iaculis faucibus elit.
    //           Aenean quis magna laoreet, facilisis tortor non, ultrices orci.
    //           Sed sed ex imperdiet, sagittis leo nec, porta dui. Nullam
    //           eleifend, nulla porttitor vestibulum rhoncus, urna ligula dictum
    //           nunc, a malesuada est enim eu tellus. Sed cursus lorem elementum
    //           tempor condimentum. In tempor eros ullamcorper, pharetra diam
    //           lobortis, lacinia nibh. Duis sit amet pretium arcu, eget maximus
    //           libero. Nullam ac egestas turpis. Lorem ipsum dolor sit amet,
    //           consectetur adipiscing elit. Vivamus a ex vel ante consequat
    //           cursus. Mauris sem diam, egestas vitae gravida ut, cursus sit amet
    //           lacus. Mauris mi arcu, lacinia ac dignissim et, iaculis sit amet
    //           erat. Cras cursus ultrices risus vel scelerisque. Etiam leo enim,
    //           tincidunt ut auctor et, iaculis eu neque. Nullam mollis purus in
    //           dui tempor, lobortis condimentum tellus venenatis. Maecenas vitae
    //           hendrerit libero, vel sagittis enim. Ut orci risus, viverra a
    //           purus sit amet, tristique dictum sapien. Nulla dapibus ipsum nec
    //           massa pretium, eget aliquam erat elementum. Praesent viverra
    //           luctus tortor sit amet lobortis. Quisque eget libero convallis
    //           augue sodales lacinia. Nulla facilisi. Fusce eu tempus risus. Ut
    //           malesuada vehicula felis, gravida porta ipsum posuere non. Proin
    //           vel dui mauris. Quisque vitae porta ex.
    //         </Typography>
    //       </Grid>
    //     </Grid>
    //   </Grid>
    // </Container>
  );
};
