import React, { useState } from 'react';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface LogoProps {
  isDark?: boolean;
  isSmall?: boolean;
  useStroke?: boolean;
}

const pageName = 'mr yeast';
const fontFamily = 'Fugaz One';

export const Logo = ({
  isDark = false,
  isSmall = false,
  useStroke = false,
}: LogoProps) => {
  const theme = useTheme();

  return (
    <>
      <Typography
        noWrap
        // component="a"
        // TODO Link to main route
        // href="#app-bar-with-responsive-menu"
        sx={{
          color: isDark
            ? theme.palette.text.primary
            : theme.palette.primary.contrastText,
          fontFamily: { fontFamily },
          fontSize: isSmall ? '2rem' : '3rem',
          fontWeight: 500,
          letterSpacing: '.5rem',
          marginY: isSmall ? '1rem' : '2rem',
          mr: 2,
          textDecoration: 'none',
          WebkitTextStroke: useStroke
            ? `1px ${theme.palette.secondary.main}`
            : '0px',
          zIndex: 100,
        }}
        variant="h5"
      >
        {pageName}
        <span style={{ color: theme.palette.secondary.main }}>.</span>
      </Typography>
    </>
  );
};
