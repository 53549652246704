import { AppRouter } from './components/Routes/AppRouter';
import { ThemeProvider, CssBaseline } from '@mui/material';

import { appTheme } from './components/Layout/Config/Themes/Theme';

export const App = () => {
  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline enableColorScheme />
      <AppRouter />
    </ThemeProvider>
  );
};

export default App;
