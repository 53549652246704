import { createTheme } from '@mui/material/styles';
import { colorConfig } from '../ColorConfig';

export const appTheme = createTheme({
  palette: {
    primary: {
      main: colorConfig.color3.darkerone,
      light: colorConfig.color3.darkone,
      dark: colorConfig.color3.darkestone,
      contrastText: colorConfig.white,
      // contrastText: colorConfig.color3.two,
    },
    secondary: {
      main: colorConfig.orange,
      light: colorConfig.color3.two,
      contrastText: colorConfig.white,
    },
    text: {
      // primary: colorConfig.color3.darkerone,
      primary: colorConfig.color2.two,
      // secondary: colorConfig.color3.five,
      secondary: colorConfig.color3.darkone,
      // primary: colorConfig.color3.five,
    },
  },
  typography: {
    fontFamily: [
      'Nunito Sans Variable',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
    fontSize: 16,
    h1: {
      fontSize: '3rem',
      fontWeight: 400,
    },
    h2: {
      fontSize: '2.66rem',
      fontWeight: 400,
    },
    h3: {
      fontSize: '2.33rem',
      fontWeight: 400,
    },
    h4: {
      fontSize: '2rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1.66rem',
      fontWeight: 400,
    },
    // body1: {
    //   color: colorConfig.color3.darkerone,
    // },
  },
});
