import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HomeView } from '../Page/Home/View';
import { RecipeView } from '../Page/Recipe/View';
import { Layout } from '../Layout/Layout';
import { Promo } from '../Page/Home/Promo/Promo';

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/home" element={<HomeView />} />
          <Route path="/recipe/:recipeId" element={<RecipeView />} />
        </Route>
        <Route path="/" element={<Promo />} />
      </Routes>
    </BrowserRouter>
  );
};
