export const colorConfig = {
  black: '#000000',
  lightgray: '#E6E6E6',
  orange: '#C9703C',
  white: '#FFFFFF',

  color1: {
    one: '#EEECEA',
    two: '#682C0A',
    three: '#F2A74B',
    four: '#593312',
    five: '#421414',
  },
  color2: {
    one: '#FFFFFF',
    two: '#211B16',
    three: '#733702',
    four: '#401201',
    five: '#27291B',
  },
  color3: {
    one: '#4C6473',
    darkone: '#3E525E',
    darkerone: '#32434D',
    darkestone: '#2A3740',
    twolight: '#FFECD4',
    // two: '#F2E0C9',
    two: '#FFEFDF',
    three: '#D97C2B',
    four: '#8C6645',
    five: '#732C02',
  },
};
