import React, { useState } from 'react';

import Grid from '@mui/system/Unstable_Grid/Grid';
import { Container, Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { Backdrop } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';

export const Spinner = () => {
  const theme = useTheme();
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid md={8} xs={12} sx={{ minHeight: '500px' }}>
          <Grid xs={12} />
          <Backdrop
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <CircularProgress color="secondary" />
          </Backdrop>
        </Grid>
      </Grid>
    </Container>
  );
};
